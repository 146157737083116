import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Config } from "../config";

@Injectable()

export class AnalyticsService {

    configUrl = new Config().apiUrl

    constructor(private http: HttpClient) { }

    getClientOrders(clientEmail) {
        return this.http.get(this.configUrl + '/order/clientSpecific/' + clientEmail)
    }

    getExpenses(param) {
        return this.http.get(this.configUrl + '/order/expenseAnalysis', { params:{query: JSON.stringify(param) }})
    }

    getAnalyticsOrders(clientID, query, p) {
        return this.http.get(this.configUrl + '/order/analyticsSearch/', { params: { query: JSON.stringify(query), pageNO: p, client_unique_ID: JSON.stringify(clientID) } })
    }

    getSelectedCriteriaCount(clientID,query){
        return this.http.get(this.configUrl + '/order/countSearch', { params: { query: JSON.stringify(query), client_unique_ID: clientID } })
    }

}