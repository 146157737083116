
import { catchError, map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Http, RequestOptions, Headers } from "@angular/http";
import { Config } from "../config";
import 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { RefreshService } from './SocketService';
import { Observable } from 'rxjs/Rx';
declare var toastr: any

@Injectable()

export class OrderService {
    configUrl = new Config().apiUrl
    constructor(private http: HttpClient, private refreshService: RefreshService) {
    }

    saveSingleOrderData(singleFormData) {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        const options = new RequestOptions({ headers: myHeaders });
        return this.http.post(this.configUrl + '/order', { order: singleFormData }).pipe(
            catchError(err => {
                toastr.error("Something went wrong")
                throw new Error(err);
            }))
    }

    getOrderData() {
        return this.http.get(this.configUrl + '/order')
    }

    getOrderDetails(orderID) {
        return this.http.get(this.configUrl + '/order/byId/' + orderID)
    }

    getUserActivities() {
        return this.http.get(this.configUrl + '/order/activities')
    }

    getOrderActivities(orderID) {
        return this.http.get(this.configUrl + '/order/' + orderID + '/activities')
    }

    saveChatData(orderID, data) {
        return this.http.post(this.configUrl + '/order/' + orderID + '/messages', { message: data })
    }

    getOrderMessages(orderID) {
        return this.http.get(this.configUrl + '/order/' + orderID + '/messages')
    }

    fileUpload(file) {
        var fd = new FormData()
        fd.append('file', file)  
        return this.http.post(this.configUrl + '/file', fd)
    }

    
    stateandcountyfileUpload(file) {
        var fd = new FormData()
        fd.append('file', file)  
        return this.http.post(this.configUrl + '/file/stateCountyFileUpload', fd)
    }

    fileUploadData(id,file){
        var fd = new FormData()
        fd.append('file', file)
        return this.http.post(this.configUrl + '/client/updatePrice/' +id, fd)
    }

    updateOrder(data: any) {
        return this.http.put(this.configUrl + '/order/', data)
    }

    updateStatus(data) {
        let id = data.id
        return this.http.post(this.configUrl + '/order/' + id + '/status/', { order: data }).map((data1: any) => {
            // this.refreshService.joinCLientList(data1.client_id)
            // if (data1.assign) {
            //     this.refreshService.joinCLientList(data1.assign.assignee)
            // }
            this.refreshService.postStatusChange(data1)
            return data1
        })
    }
    getStatusCount() {
        return this.http.get(this.configUrl + '/order/statusCount')
    }

    saveBatchOrder(batchOrderData) {
        return this.http.post(this.configUrl + '/order/batch/', batchOrderData)
    }

    saveFeedbackData(feedbackData, id) {
        return this.http.post(this.configUrl + '/order/feedback/' + id, feedbackData)
    }

    saveFulfillmentData(fulfillmentData) {
        return this.http.post(this.configUrl + '/order/nonPlusType/', fulfillmentData)
    }

    getFulfillmentData(orderID) {
        return this.http.get(this.configUrl + '/order/nonPlusType/' + orderID)
    }

    saveFulfillmentPlusTypeData(fulPlusTypeData) {
        return this.http.post(this.configUrl + '/order/plusType/', fulPlusTypeData)
    }

    getFulfillmentPlusTypeData(orderID) {
        return this.http.get(this.configUrl + '/order/plusType/' + orderID)
    }
    clarify(data) {
        return this.http.post(this.configUrl + '/order/clarify/', data).map((data1: any) => {
            // this.refreshService.joinCLientList(data1.client_id)
            // if (data1.assign) {
            //     this.refreshService.joinCLientList(data1.assign.assignee)
            // }
            this.refreshService.postStatusChange(data1)
            return data1
        })
    }
    onTypeSearch(cond) {
        return this.http.post(this.configUrl + '/order/search/', cond)
    }

    getBatchOrders() {
        return this.http.get(this.configUrl + '/order/batch')
    }

    getAllNotifications(page) {
        return this.http.get(this.configUrl + '/order/getEmpNotification', { params: { pageNO: page } })
    }

    getNotificationCount(query) {
        return this.http.get(this.configUrl + '/order/getActivityCount', { params: { query: JSON.stringify(query) } })
    }

    MarkNotificationsAsRead() {
        return this.http.post(this.configUrl + '/order/updateNotification', {})
    }

    assignOrder(assignData) {
        return this.http.post(this.configUrl + '/assign/', { assign: assignData })
    }

    getAssignOrder(pageNo, query) {
        return this.http.get(this.configUrl + '/assign/', { params: { pageNo: pageNo, query: JSON.stringify(query) } })
    }

    saveExpenses(orderID, expensesData, total) {
        return this.http.post(this.configUrl + '/order/expense', { expense: { order_id: orderID, expense: expensesData, total: total } })
    }

    getExpenses(orderID) {
        return this.http.get(this.configUrl + '/order/expense/' + orderID)
    }

    updateBatchOrder(batchID) {
        return this.http.put(this.configUrl + '/order/batch', batchID)
    }

    savePlottedFulfillment(data) {
        return this.http.post(this.configUrl + '/order/plottedEasements', data)
    }

    getPlottedFulfillment(orderID) {
        return this.http.get(this.configUrl + '/order/plottedEasements/' + orderID)
    }

    // getClientOrders() {
    //     return this.http.get(this.configUrl + '/order', { params: this.email })
    // }

    getOrderFeedback(orderID) {
        return this.http.get(this.configUrl + '/order/feedback/' + orderID)
    }

    saveMaster(data) {
        return this.http.post(this.configUrl + '/manage/master/', data)
    }

    stateandcountysaveMaster(data) {
        return this.http.post(this.configUrl + '/manage/master/stateCountyFileUpload', data)
    }



    getMaster(type) {
        return this.http.get(this.configUrl + '/manage/master/', { params: {type:JSON.stringify(type)} })
    }

    getSearchData(query) {
        // return this.http.get(this.configUrl + '/order/selectedSearch/', { params: { query: JSON.stringify(query), pageNO: p } })
        return this.http.post(this.configUrl + '/order/getPartialOrders/', query)
    }

    saveInvoice(data) {
        return this.http.post(this.configUrl + '/invoice/create', data)
    }

    getInvoices(p, query) {
        return this.http.get(this.configUrl + '/invoice/get', { params: { query: JSON.stringify(query), pageNO: p } })
    }

    getInvoiceExpense(orderID) {
        return this.http.get(this.configUrl + '/invoice/getInvoiceExpense', { params: { order_id: orderID } })
    }

    despatchInvoice(orderID) {
        return this.http.get(this.configUrl + '/invoice/dispatch/' + orderID)
    }

    saveFinalInvoice(data) {
        return this.http.post(this.configUrl + '/invoice/finalInvoice', data)
    }

    updateInvoice(id) {
        return this.http.post(this.configUrl + '/invoice/update', id)
    }
    joinRoom() {
        return this.http.get(this.configUrl + '/auth/joinRoom')
    }

    downloadPDF(Id){
        return this.http.get(this.configUrl + '/file/ejs/', Id)
    }

    errorCategoryData(data) {
        return this.http.post(this.configUrl + '/manage/getErrCatErrSubCatBasedOnsegmentId', data)
    }

    getGenerateApi(clientID) {
        return this.http.get(this.configUrl + '/order/generatePublicApiKey', { params: {query:JSON.stringify(clientID)}})
    }

    fetchPublicApiKey(clientID) {
        console.log("client fdetsssssssssssss ",clientID)
        return this.http.get(this.configUrl + '/order/fetchPublicApiKey', { params: {query:JSON.stringify(clientID)}})
    }

}
