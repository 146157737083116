export class Config {
  //public apiUrl = "http://localhost:8080/api/v1"
   //public apiUrl="http://192.168.1.15:8080/api/v1/"

    // Production Api
   public apiUrl="https://api.closeville.com/api/v1"
    // prod -end

    // Development Api
    // public apiUrl="http://18.223.145.228:8085/api/v1" 


      // url = "http://18.223.145.228:3000"

   url = "https://api.closeville.com:3000/socket.io"
 
}
