import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './/app-routing.module';
import { AppComponent } from './app.component';
import { HttpModule } from '@angular/http';
import { OrderService } from "./services/OrderService";
import { searchService } from "./services/SearchService";
import { ClientService } from "./services/ClientService";
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { LoginService } from './services/LoginService';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './services/AuthService';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxUiLoaderModule } from "ngx-ui-loader"
import { CommonModule } from '@angular/common';
import { UserIdleModule } from 'angular-user-idle';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    FormsModule,
    AppRoutingModule,
    AngularMultiSelectModule,
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxUiLoaderModule,
    CommonModule,
    UserIdleModule.forRoot({idle: 1800, timeout: 1, ping: 12})
  ],
  providers: [
    OrderService,
    searchService,
    ClientService,
    LoginService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
